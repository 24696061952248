// initial state
import api from "@/common/services/api";
import toQueryString from "@/common/helpers/toQueryString";
import fromQueryToObject from "@/common/helpers/fromQueryToObject";
import store from '@/store/index'

const state = () => ({
    loadingPosts: false,
    loadingPost: false,
    postsLoaded: false,
    postLoaded: false,
    postsData: [],
    postData: null,
    currentCategory: null,
    pagination: {
        currentPage: null,
        from: null,
        lastPage: null,
        perPage: null,
        to: null,
        total: null,
    },
    searchQuery: null,
    emptyPost: {},
    lastPage: 1,
    lastCategory: null,
    currentOnlyPostSlug: null,

})

// getters
const getters = {
    loadingPosts: state => state.loadingPosts,
    loadingPost: state => state.loadingPost,
    postsLoaded: state => state.postsLoaded,
    postLoaded: state => state.postLoaded,
    postsData: state => state.postsData,
    pagination: state => state.pagination,
    postData: state => state.postData,
    searchQuery: state => state.searchQuery,
    emptyPost: state => state.emptyPost,
    currentCategory: state => state.currentCategory,
    lastPage: state => Number(state.lastPage),
    lastCategory: state => state.lastCategory,
    currentOnlyPostSlug: state => state.currentOnlyPostSlug,

};

//actions
const actions = {
    fetchPostsRequest: ({ commit, state, dispatch}, payload) => {
        commit('setLoadingPosts', true);
        commit('setPostsLoaded', false);
        api.setLocale();

        api.query(`/posts`, payload, true)
            .then(
                success => {
                    commit('setPostsData', success.data)
                    commit('setPagination', success.meta)
                    commit('setCurrentCategory', success.meta.postCategory)
                    commit('setPostsLoaded', true);
                },
                error => {
                    dispatch('alert/error', error, { root: true });
                }
            ).finally(() => {
                commit('setLoadingPosts', false);
        });
    },

    fetchPostShowRequest: ({ commit, dispatch}, payload) => {
        commit('setLoadingPost', true);
        commit('setPostLoaded', false);

        api.setLocale();
        api.get(`/posts`, payload.slug, false)
            .then(
                success => {
                    commit('setPostData', success.data)
                    commit('setCurrentCategory', success.data.postCategory)
                    commit('setPostLoaded', true);
                },
                error => {
                    dispatch('alert/error', error, { root: true });
                }
            ).finally(() => {
            commit('setLoadingPost', false);
        });
    },

    fetchEmptyPostRequest: ({ commit, dispatch}) => {

        api.setLocale();
        api.get(`/posts/get-empty`, '')
            .then(
                success => {
                    commit('setEmptyPostData', success.data)
                },
                error => {
                    dispatch('alert/error', error, { root: true });
                }
            ).finally(() => {
        });
    },

}

//mutations
const mutations = {
    setLoadingPosts (state, data) {
        state.loadingPosts = data;
    },
    setPostsLoaded (state, value) {
        state.postsLoaded = value;
    },
    setPostsData(state, data) {
        state.postsData = data;
    },
    setLoadingPost (state, data) {
        state.loadingPost = data;
    },
    setPostLoaded (state, value) {
        state.postLoaded = value;
    },
    setPostData(state, data) {
        state.postData = data;
    },
    setEmptyPostData(state, data) {
        state.emptyPost = data;
    },
    setPagination (state, data) {
        state.pagination = {
            currentPage: data.current_page,
            from: data.from,
            lastPage: data.last_page,
            perPage: data.per_page,
            to: data.to,
            total: data.total,
        }
    },
    setCurrentCategory (state, value) {
        state.currentCategory = value;
    },
    setCurrentPage (state, value) {
        state.pagination.currentPage = value;
    },
    setSearchQuery (state, value) {
        state.searchQuery = value;
    },
    setLastPage(state, page) {
        state.lastPage = page;
    },
    setCurrentOnlyPostSlug(state, slug) {
        state.currentOnlyPostSlug = slug;
    }
}

export const posts = {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,

}
