import Vue from 'vue'
import Vuex from 'vuex'
import app from './app'
import { createModule } from 'vuex-toast'
import 'vuex-toast/dist/vuex-toast.css'
import { alert } from './app/alert';
import { account } from '@/store/app/account';
import {lang} from "@/store/app/lang";
import {users} from "@/store/app/users"
import {profile} from "@/store/app/profile"
import {chat} from "@/store/app/chat"
import {dictionaries} from '@/store/app/dictionaries';
import {favourites} from "@/store/app/favourites";
import {settings} from "@/store/app/settings";
import {posts} from "@/store/app/posts";
import {newsletter} from "@/store/app/newsletter";
import {dashboard} from "@/store/app/dashboard";
import {loader} from "@/store/app/loader";
import {verification} from "@/store/app/verification";
import {gallery} from "@/store/app/gallery";
import {admin} from "@/store/app/admin";
import {contact} from "@/store/app/contact";
import {adminPartners} from "@/store/app/admin/adminPartners";
import {partners} from "@/store/app/partners";
import {partnerCodes} from "@/store/app/admin/partnerCodes";
import {packages} from "@/store/app/admin/packages";
import {subscriptions} from "@/store/app/subscriptions";
import {searchHistories} from "@/store/app/search-history";
import {users as adminUsers} from "@/store/app/admin/users"
import {utils} from "@/store/app/utils";

import config from "@/config";
import VueAxios from "vue-axios";
import axios from "axios";
import {permissions} from "@/store/app/permissions";

Vue.use(Vuex)

Vue.use(VueAxios, axios);
Vue.axios.defaults.baseURL = config.API_URL;
export default new Vuex.Store({
  state: {},
  mutations: {},
  actions: {},
  modules: {
    app,
    alert,
    account,
    lang,
    chat,
    users,
    favourites,
    settings,
    posts,
    profile,
    dictionaries,
    newsletter,
    dashboard,
    loader,
    gallery,
    admin,
    contact,
    verification,
    adminPartners,
    partners,
    partnerCodes: partnerCodes,
    packages,
    subscriptions,
    adminUsers,
    utils,
    permissions,
    searchHistories,
    toast: createModule({
      dismissInterval: 8000
    }),
  },
})
