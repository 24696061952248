<template>
  <svg width="16" height="16" viewBox="0 0 16 16"  >
    <path d="M12.4883 10.5812C13.279 9.50461 13.7461 8.1755 13.7461 6.7373C13.7461 3.14745 10.8359 0.237305 7.24609 0.237305C3.65624 0.237305 0.746094 3.14745 0.746094 6.7373C0.746094 10.3272 3.65624 13.2373 7.24609 13.2373C8.68468 13.2373 10.0141 12.77 11.0908 11.9788L11.09 11.9795C11.1195 12.0195 11.1523 12.0577 11.1885 12.094L15.039 15.9444C15.4295 16.3349 16.0627 16.3349 16.4532 15.9444C16.8437 15.5539 16.8437 14.9207 16.4532 14.5302L12.6027 10.6797C12.5665 10.6435 12.5282 10.6107 12.4883 10.5812ZM12.7461 6.7373C12.7461 9.77487 10.2837 12.2373 7.24609 12.2373C4.20853 12.2373 1.74609 9.77487 1.74609 6.7373C1.74609 3.69974 4.20853 1.2373 7.24609 1.2373C10.2837 1.2373 12.7461 3.69974 12.7461 6.7373Z" fill="#979797"/>
  </svg>

</template>

<script>
export default {
  name: "SearchIcon",
}
</script>
