<template>
  <div class="absolute top-12 flex w-full items-center justify-center">
    <div class="flex flex-col justify-end">
      <div class="bg-redAccent border-b border-white items-center flex flex-row flex-wrap lg:flex-nowrap gap-x-2 gap-y-1 lg:gap-6 px-3 lg:px-6 pt-1 pb-2 lg:pb-1 "
        v-scroll-spy-active="{class: 'active-link'}"
        v-scroll-spy-link="{selector: 'button.menu-link'}"
      >

        <Button
          variant="homepageLink"
          class="menu-link"
          :text="trans('ABOUT:TopLink')"
        />
        <Button
          variant="homepageLink"
          class="menu-link ml-1"
          :text="trans('ABOUT:WhatLink')"
        />
        <Button
          variant="homepageLink"
          class="menu-link"
          :text="trans('ABOUT:WhyLink')"
        />
        <Button
          variant="homepageLink"
          class="menu-link"
          :text="trans('ABOUT:HowWeLink')"
        />
        <Button
          variant="homepageLink"
          class="menu-link"
          :text="trans('ABOUT:HowToLink')"
        />
        <Button
          variant="homepageLink"
          class="menu-link"
          :text="trans('ABOUT:AuthorLink')"
        />
        <Button
          variant="homepageLink"
          class="menu-link mr-1"
          :text="trans('ABOUT:SubscribeLink')"
        />
      </div>
      <div class="flex flex-row justify-between">
        <div/>
<!--        <div class="flex flex-row gap-1 justify-end py-1 px-3 bg-white border-b border-solid border-redAccent">-->
<!--          <a href="https://veriff.com" target="_blank"><span class="flex flex-row gap-1 text-xs items-center">{{ trans('LABEL:DataSecurity1') }} {{ trans('LABEL:DataSecurity2') }} <img class="w-12 mb-1" src="https://veriff.cdn.prismic.io/veriff/1565ec7d-5815-4d28-ac00-5094d1714d4c_Logo.svg"/></span></a>-->
<!--        </div>-->
        <div class="flex flex-row justify-end py-1 px-3 gap-3 bg-white border-b border-solid border-redAccent">
          <a href="https://veriff.com" target="_blank"><span class="flex flex-row gap-1 text-xs items-center">{{ trans('LABEL:DataSecurity1') }} <img class="w-12 mb-1" src="https://veriff.cdn.prismic.io/veriff/1565ec7d-5815-4d28-ac00-5094d1714d4c_Logo.svg"/></span></a>
          <a href="https://durex.pl" target="_blank"><span class="flex flex-row gap-1 text-xs items-center">{{ trans('LABEL:DataSecurity2') }} <img class="w-12" src="https://seeklogo.com/images/D/Durex-logo-449353687D-seeklogo.com.png"/></span></a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Button from "@/utils/crud/components/Button";
export default {
  name: "NavigationHomepage",
  components: {Button}
}
</script>

<style lang="scss">
  .active-link {
    color: theme('colors.white') !important;
    opacity: .6 !important;
    &:hover {
      cursor: default;
    }
  }
</style>