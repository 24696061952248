import api from "@/common/services/api";
import {dynamicSort} from "@/common/helpers/utils";

const state = () => ({
    currentSearchNav: 'favourites',
    filtersShown: true,
    picturesLoadingCounter: 0,

})

// getters
const getters = {
    currentSearchNav: state => state.currentSearchNav,
    filtersShown: state => state.filtersShown,
    picturesLoadingCounter: state => state.picturesLoadingCounter,
};

//actions
const actions = {

}

//mutations
const mutations = {
    setCurrentNav (state, value) {
        state.currentSearchNav = value;
    },
    setFiltersShown (state, value) {
        state.filtersShown = value;
    },
    setPicturesLoadingCounter(state, value) {
        state.picturesLoadingCounter = value;
    },

}

export const utils = {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,

}
