<template>
	<div ref="notifications"
	     class="relative flex flex-row items-center z-20"
	>
		<button
      variant="flag"
      @click.stop="toggleDropdown()"
		>
			<img :src="require(`@/assets/images/flags/${selectedLocale}.png`)" alt="flag"
        class="rounded-full border border-black h-6 w-6 ml-1 lg:ml-0 mr-1 lg:mr-0"
      >
		</button>
		<div v-if="areLanguagesVisible"
      class="absolute -left-3 top-9 transform z-20 bg-white border-t border-grayedDark border-dashed w-max"
		>
			<!-- Dropdown -->
			<div
        class="bg-white text-base text-black border-b"
        :class="$route.name === 'homepage'?'border-redAccent':'border-black'"
			>
				<div class="flex flex-col p-3 gap-3 bg-transparent justify-start items-start">
          <div
            v-for="langLocale in locales"
            v-if="langLocale !== selectedLocale"
            @click="updateLanguageAction(langLocale)"
            :key="langLocale"
            role="menuitem"
            :class="{'active': selectedLocale === langLocale}"
            class="block cursor-pointer flex flex-row gap-3"
          >
            <img :src="require(`@/assets/images/flags/${langLocale}.png`)" alt="flag"
              class="h-6 w-6 rounded-full border border-black cursor-pointer"
            >
            <div>
              {{trans("LANGUAGE:"+langLocale)}}
            </div>
          </div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import Button from "@/utils/crud/components/Button";
import {mapActions, mapGetters} from "vuex";
export default {
  name: "LanguageSelect",
  components: {Button},
  data() {
    return {
      selectedLocale: localStorage.getItem('langLocale'),
	    isLangFocused: false,
	    areLanguagesVisible: false,

    }
  },
  computed: {
    ...mapGetters('lang', ['langData', 'langLocale', 'locales', 'langLoaded']),
  },
	watch: {
		'$refs.languageSelect.hasFocus'(val) {
		}
	},
  methods: {
    ...mapActions('lang', ['fetchLangData', 'updateLang']),
    updateLanguageAction(lang) {
      this.fetchLangData(lang);
      this.selectedLocale = lang;
    },

	  toggleDropdown() {
		  if (this.areLanguagesVisible) {
			  this.$root.$emit('hideLanguages')
		  } else {
			  this.$root.$emit('showLanguages')
		  }

		  this.areLanguagesVisible = !this.areLanguagesVisible;
	  },


	  hide() {
		  this.areLanguagesVisible = false;
		  this.$root.$emit('hideLanguages')
	  },
  },
}
</script>